[id^="react-joyride-step-"] {
  /**
   *  Always show beacon
   */
  & > span {
    visibility: visible !important;
  }
}

.react-joyride__tooltip {
  h4 {
    text-align: left;
  }

  div {
    text-align: left;
  }
}

.__floater {
  padding-left: 0 !important;

  &__arrow {
    display: none;
  }
}

.TDB-Tour__tooltip {
  align-items: flex-start;
  background-color: var(--color-bg-surface-secondary-default);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 320px;
  padding: 2rem 3rem;
  position: relative;

  &__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    margin-right: 4rem;
  }

  &__close-btn {
    position: relative;
    right: -1rem;

    svg {
      path {
        fill: var(--color-text-default) !important;
      }
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    width: 100%;

    &__dismiss-items {
      align-items: center;
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
    }
  }
}
