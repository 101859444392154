@import "../../assets/scss/breakpoints";

.TDB-Header {
  align-items: center;
  background-color: var(—color-bg-surface-default);
  border-bottom: 1px solid var(--color-border-disabled);
  display: flex;
  flex-shrink: 0;
  height: var(--header-height);
  justify-content: space-between;
  padding: var(--space-100) var(--space-300);
  width: 100%;

  &--transparent {
    background-color: transparent;
    border-bottom-color: transparent;
  }

  &--floating {
    box-shadow: var(--shadow-small-elevated);
  }

  &__Title {
    align-items: center;
    display: flex;
    flex: 1;
    max-width: 600px;
    white-space: nowrap;

    @include mobileBreakpoint {
      max-width: calc(100% - 32px);
    }

    &__Text {
      display: none;

      @include mobileBreakpoint {
        display: flex;
        font-size: 2rem !important;
      }
    }

    &__Icon {
      height: 3rem;
      margin-right: 1rem;
    }
  }

  &__BackButton {
    display: inline-block;
    margin-right: 2rem;

    svg {
      height: 2.5rem !important;
      width: 2.5rem !important;
    }
  }

  &__Children {
    padding: 0 2rem;
    width: 100%;

    @include mobileBreakpoint {
      padding: 0 1.5rem;
    }
  }

  @include mainSidebarCollapseBreakpoint {
    height: var(--header-height-mobile);
    padding: 0 2rem;
    width: 100% !important;
  }

  &__WorkspaceMenuButton {
    align-items: center;
    background-color: transparent;
    border-radius: 1rem;
    display: flex;
    padding: 0.6rem 1.2rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--action-background-hovered);
      cursor: pointer;
    }

    &__NameWrapper {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
    }

    &__Name {
      font-size: 12px;
      line-height: 14px;
    }

    &__Namespace {
      font-size: 10px;
      line-height: 14px;
    }

    &__Arrow {
      margin-left: 1rem;
    }
  }

  &__aside {
    align-items: center;
    display: flex;
    gap: 1rem;
  }
}
