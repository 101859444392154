@import "../../assets/scss/breakpoints";

.TDB-Console {
  &__OuterWrapper {
    background-color: var(--color-bg-app-default);
    display: flex;
    flex: 1;
    height: 100%;
    padding: 1rem;
    z-index: 1;

    @include tabletBreakpoint {
      padding: 0; // removes slack-like round border
    }
  }

  &__inner-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
}
