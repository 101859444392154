.TDB-MainMenuButton {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  &__tile {
    align-items: center;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    transition: background-color 0.1s ease;
  }

  &__icon {
    fill: var(--color-text-app-default);
  }

  &__text {
    color: var(--color-text-app-selected);
    font-size: 9px;
  }

  &:hover {
    cursor: pointer;

    .TDB-MainMenuButton__tile {
      background-color: var(--color-bg-app-hovered);
    }
  }

  &--active {
    .TDB-MainMenuButton__tile {
      background-color: var(--color-bg-app-selected) !important;
    }

    .TDB-MainMenuButton__icon {
      fill: var(--color-text-app-selected);
    }

    .TDB-MainMenuButton__text {
      color: var(--color-text-app-selected);
    }
  }

  &__indicator {
    position: absolute;
    right: 10px;
    top: 5px;

    &:hover {
      background-color: #282828;
    }
  }
}
