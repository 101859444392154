.TDB-Spinner {
  align-items: center;
  display: flex;

  svg {
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
  }

  &--fast {
    svg {
      animation-duration: 1000ms;
    }
  }

  &--slow {
    svg {
      animation-duration: 3000ms;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
