.TDB-InputText {
  display: flex;
  height: 5rem;
  position: relative;

  &::after {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-150);
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:hover {
    &::after {
      border-color: var(--color-border-hover);
    }
  }

  &.has-focus {
    outline: none;

    &::after {
      border-color: var(--color-border-focus) !important;
      border-width: 2px;
    }
  }

  input {
    background-color: var(--color-bg-surface-default);
    border: none;
    border-radius: 0.75rem;
    color: var(--text-main-primary);
    font-size: 1.7rem;
    font-weight: 500;
    height: 5rem;
    padding: 0 2rem;
    transition: all 0.2s ease;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--text-main-disabled);
    }

    &:disabled {
      background-color: var(--color-bg-surface-disabled) !important;
      cursor: not-allowed;
    }
  }

  &--small {
    height: 4rem;

    input {
      height: 4rem;
    }
  }

  &.is-disabled {
    &::after {
      border-color: var(--border-input-disabled) !important;
      border-width: 2px;
    }

    .TDB-InputText__Button {
      pointer-events: none;

      svg {
        fill: var(--text-main-tertiary);
      }
    }
  }

  &.has-error {
    &::after {
      border-color: var(--border-input-error) !important;
      border-width: 2px;
    }

    input {
      background-color: var(--surface-primary);

      &:hover {
        background-color: var(--surface-primary);
      }

      &:focus {
        background-color: var(--surface-primary);
      }
    }
  }

  &.has-button {
    input {
      padding-right: 3.5rem;
    }
  }

  &__Button {
    align-items: center;
    background-color: transparent;
    border: none;
    bottom: 0;
    box-shadow: none;
    display: flex;
    height: 4rem;
    margin: auto;
    margin-right: 0.5rem;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 4rem;

    svg {
      fill: var(--text-main-tertiary);
    }
  }

  &.has-icon {
    input {
      padding-left: 5rem;
    }
  }

  &__Icon {
    background-color: transparent !important;
    bottom: 0;
    left: 1.5rem;
    margin: auto;
    position: absolute;
    top: 0;
  }
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: none;
  -webkit-text-fill-color: var(--text-main-primary);
  transition: background-color 5000s ease-in-out 0s;
}

/**
  * Overwrite browser-specific style for (x) native button of search input field
  */
input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
  background-size: 10px 10px;
  cursor: pointer;
  height: 10px;
  width: 10px;
}

[data-theme="dark"] {
  input[type="search"]::-webkit-search-cancel-button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTIzLjA1cHgiIGhlaWdodD0iMTIzLjA1cHgiIHZpZXdCb3g9IjAgMCAxMjMuMDUgMTIzLjA1Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik0xMjEuMzI1LDEwLjkyNWwtOC41LTguMzk5Yy0yLjMtMi4zLTYuMS0yLjMtOC41LDBsLTQyLjQsNDIuMzk5TDE4LjcyNiwxLjcyNmMtMi4zMDEtMi4zMDEtNi4xMDEtMi4zMDEtOC41LDBsLTguNSw4LjUNCgkJYy0yLjMwMSwyLjMtMi4zMDEsNi4xLDAsOC41bDQzLjEsNDMuMWwtNDIuMyw0Mi41Yy0yLjMsMi4zLTIuMyw2LjEsMCw4LjVsOC41LDguNWMyLjMsMi4zLDYuMSwyLjMsOC41LDBsNDIuMzk5LTQyLjRsNDIuNCw0Mi40DQoJCWMyLjMsMi4zLDYuMSwyLjMsOC41LDBsOC41LTguNWMyLjMtMi4zLDIuMy02LjEsMC04LjVsLTQyLjUtNDIuNGw0Mi40LTQyLjM5OUMxMjMuNjI1LDE3LjEyNSwxMjMuNjI1LDEzLjMyNSwxMjEuMzI1LDEwLjkyNXoiIGZpbGw9IndoaXRlIi8+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
  }
}
