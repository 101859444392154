.TDB-Snackbar {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.75rem;
  box-shadow: var(--shadow-medium-elevated);
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 380px;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(16px);
    background-color: rgba(255, 255, 255, 0.74);
  }

  &__ContentWrapper {
    width: 100%;
  }

  &__Gradient {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__Content {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 2rem 8rem 2rem 2rem;
    position: relative;
    z-index: 2;
  }

  &__Icon {
    margin-right: 2rem;
  }

  &__Text {
    display: flex;
    flex-direction: column;
    width: 100%;
    word-wrap: break-word;
  }

  &__Title {
    max-width: calc(100% - 2rem);
  }

  &__Description {
    margin-top: 1rem;
  }

  &__DismissButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 2;

    &:hover {
      cursor: pointer;
    }
  }

  &--auto-dismiss {
    .TDB-Snackbar__Timer {
      background-color: var(--background-secondary);
      height: 0.5rem;
      width: 100%;

      &__Indicator {
        animation: snackbar-progress 10s forwards linear;
        height: 100%;
      }
    }
  }

  &--success {
    .TDB-Snackbar__Gradient {
      background: linear-gradient(
        90deg,
        rgba(0, 112, 240, 0.08) 0%,
        rgba(0, 112, 240, 0) 44.31%
      );
    }

    &.TDB-Snackbar--auto-dismiss {
      .TDB-Snackbar__Timer__Indicator {
        background-color: var(--action-primary);
      }
    }
  }

  &--error {
    .TDB-Snackbar__Gradient {
      background: linear-gradient(
        90deg,
        rgba(164, 0, 0, 0.08) 0%,
        rgba(164, 0, 0, 0) 44.31%
      );
    }

    .TDB-Snackbar__Timer__Indicator {
      background-color: var(--error-primary);
    }
  }
}

[data-theme="dark"] {
  .TDB-Snackbar {
    background-color: rgba(24, 30, 34, 1);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: rgba(24, 30, 34, 0.84);
    }

    &--success {
      .TDB-Snackbar__Gradient {
        background: linear-gradient(
          90deg,
          rgba(0, 112, 240, 0.3) 0%,
          rgba(0, 112, 240, 0) 44.31%
        );
      }
    }

    &--error {
      .TDB-Snackbar__Gradient {
        background: linear-gradient(
          90deg,
          rgba(220, 0, 0, 0.24) 0%,
          rgba(164, 0, 0, 0) 44.31%
        );
      }
    }
  }
}

@keyframes snackbar-progress {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
