.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-200);
  min-width: 227px;
  padding: var(--space-200);
}

.item {
  align-items: center;
  display: flex;
  gap: var(--space-200);
  justify-content: space-between;
  padding: var(--space-200) var(--space-300);

  &:hover {
    background-color: var(--color-bg-surface-hover);
    border-radius: var(--border-radius-100);
  }
}

.itemLabel {
  align-items: center;
  display: flex;
  gap: var(--space-200);
}
