.TDB-WorkspaceItem {
  align-items: center;
  border-radius: var(--space-150);
  display: flex;
  justify-content: space-between;
  min-width: 280px;
  padding: var(--space-200) var(--space-400);

  &:hover {
    background-color: var(--color-bg-surface-hover);
    cursor: pointer;
  }

  &--active {
    background-color: var(--color-bg-surface-selected);
  }

  &__content {
    align-items: center;
    display: flex;
  }
}
