@import "../breakpoints";

.fill-height {
  height: 100% !important;
}

.fill-width {
  width: 100% !important;
}

.br-1 {
  border-radius: 1rem;
}

.hover {
  &-opacity {
    opacity: 0.8;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  &-link {
    color: var(--azure) !important;

    &:hover {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }

  &-pointer {
    cursor: pointer !important;
  }
}

.no-select {
  -webkit-touch-callout: none;
  user-select: none;
}

.color {
  &--text-primary {
    color: var(--text-primary);
  }

  &--text-secondary {
    color: var(--text-primary);
  }
}

.no-decoration {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.auto-width {
  width: auto !important;
}

.white-space--nowrap {
  white-space: nowrap;
}

.max-width-100 {
  max-width: 100%;
}

.mobile-first {
  @include mobileBreakpoint {
    order: -1;
  }
}
