.TDB-WorkspaceOption {
  align-items: center;
  border-radius: var(--space-150);
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;

  &:hover {
    background-color: var(--color-bg-surface-hover);
    cursor: pointer;
  }

  &--active {
    background-color: var(--color-bg-surface-selected);
  }

  &__content {
    align-items: center;
    display: flex;
    gap: var(--space-300);
  }
}
