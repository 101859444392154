@import "./assets/scss/reset";
@import "./assets/scss/design-system";
@import "./assets/scss/typography";
@import "./assets/scss/layout";
@import "./assets/scss/classes";
@import "./assets/scss/constants";
@import "./assets/scss/variables";

html,
body {
  color: var(--text-main-primary);
  font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  text-rendering: geometricprecision;
}

/**
 * Class added to body for theme transition
 */
.changing-theme,
.changing-theme * {
  transition: all 0.2s ease !important;
}

* {
  box-sizing: border-box; // ftw
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    flex: 1;
  }
}

div[role="group"] {
  flex: 1;
}

svg {
  display: inline-block;
  pointer-events: none;
  transition: fill 0.2s ease;

  title {
    visibility: hidden;
  }
}

p {
  line-height: 1.33;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--background-primary) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.noscroll {
  overflow: hidden !important;
}

code > * {
  font-family: monospace;
}

strong {
  font-weight: 800;
}

/**
 * <a> tag's color is reset
 * use .link for link-like styling
 */
.link {
  color: var(--text-action-brand) !important;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.link2 {
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}
