.free-tier {
  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--space-300);
  }

  &__bullet {
    margin-bottom: var(--space-200);
  }
}
