.TDB-NotificationItem {
  align-items: center;
  background-color: var(--color-bg-surface-default);
  border-radius: 0.75rem;
  display: flex;
  margin-bottom: 1rem;
  padding: 1.25rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--color-bg-surface-hover);
  }

  &__date {
    line-height: 1;
  }

  &__status {
    align-items: center;
    display: flex;
    height: 3.75rem;
    justify-content: center;
    margin-right: 1rem;
    width: 3.75rem;

    &__dot {
      background-color: var(--text-main-disabled);
      border-radius: 50%;
      height: 1rem;
      width: 1rem;

      &--active {
        background-color: var(--text-action-brand);
      }
    }
  }

  &__icon {
    align-items: center;
    background-color: var(--surface-secondary);
    border-radius: 0.75rem;
    display: flex;
    height: 3.75rem;
    justify-content: center;
    width: 3.75rem;
  }

  &__main {
    flex: 1;
    margin-left: 1rem;
  }

  &__description {
    line-height: 2.5rem;
    word-break: break-all;
  }

  &__actions {
    align-items: center;
    display: flex;
    z-index: 2;

    &__button {
      .TDB-Svg {
        fill: var(--text-main-tertiary) !important;
      }
    }
  }

  strong {
    color: var(--text-main-primary);
  }
}
