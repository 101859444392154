.container {
  align-items: center;
  background-color: #1c1c1c;
  border-radius: 2rem;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 0.5rem;

  &:hover {
    background-color: #282828;
  }
}

.active,
.active:hover {
  background-color: #2e2e2e;
}

.inner {
  border-radius: 14px;
  height: 9px;
  width: 9px;
}

.info {
  background: var(--color-bg-fill-info-default);
}

.warning {
  background: var(--color-bg-fill-warning-default);
}
