/*
 * This file includes all variables taken from `_variables--figma-export.scss` file
 * The exported file can't be used as-is but we need it in order to keep track of versions.
 * Please update the exported file before touching this file.
 */

:root {
  // breakpoints
  --breakpoint-width-xl: 1920px;
  --breakpoint-width-lg: 1040px;
  --breakpoint-width-md: 768px;
  --breakpoint-width-sm: 430px;

  // z-indexes
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-5: 5;
  --z-index-6: 6;
  --z-index-7: 7;
  --z-index-8: 8;
  --z-index-9: 9;
  --z-index-10: 10;

  // border-radius
  --border-radius-0: 0;
  --border-radius-050: 2px;
  --border-radius-100: 4px;
  --border-radius-150: 6px;
  --border-radius-200: 8px;
  --border-radius-300: 12px;
  --border-radius-400: 16px;
  --border-radius-500: 20px;
  --border-radius-full: 9999px;

  // border-widths
  --border-width-025: 1px;
  --border-width-050: 2px;
  --border-width-100: 4px;

  // heights
  --height-0: 0;
  --height-025: 1px;
  --height-050: 2px;
  --height-100: 4px;
  --height-150: 6px;
  --height-200: 8px;
  --height-300: 12px;
  --height-400: 16px;
  --height-500: 20px;
  --height-600: 24px;
  --height-700: 28px;
  --height-800: 32px;
  --height-900: 36px;
  --height-1000: 40px;
  --height-1200: 48px;
  --height-1600: 64px;
  --height-2000: 80px;
  --height-2400: 96px;
  --height-2800: 112px;
  --height-3200: 128px;

  // spacings
  --space-0: 0;
  --space-025: 1px;
  --space-050: 2px;
  --space-100: 4px;
  --space-150: 6px;
  --space-200: 8px;
  --space-250: 10px;
  --space-300: 12px;
  --space-400: 16px;
  --space-500: 20px;
  --space-600: 24px;
  --space-800: 32px;
  --space-1000: 40px;
  --space-1200: 48px;
  --space-1600: 64px;
  --space-2000: 80px;
  --space-2400: 96px;
  --space-2800: 112px;
  --space-3200: 128px;

  // widths
  --width-0: 0;
  --width-025: 1px;
  --width-050: 2px;
  --width-100: 4px;
  --width-150: 6px;
  --width-200: 8px;
  --width-300: 12px;
  --width-400: 16px;
  --width-500: 20px;
  --width-600: 24px;
  --width-700: 28px;
  --width-800: 32px;
  --width-900: 36px;
  --width-1000: 40px;
  --width-1200: 48px;
  --width-1600: 64px;
  --width-2000: 80px;
  --width-2400: 96px;
  --width-2800: 112px;
  --width-3200: 128px;

  // Shadows
  --shadow-blur-none: 0;
  --shadow-blur-100: 1px;
  --shadow-blur-200: 3px;
  --shadow-blur-300: 6px;
  --shadow-blur-400: 16px;
  --shadow-blur-500: 20px;
  --shadow-blur-600: 20px;
  --shadow-spread-600: -4px;
  --shadow-spread-500: -2px;
  --shadow-spread-100: 0;
  --shadow-spread-200: 0;
  --shadow-spread-300: 0;
  --shadow-spread-400: 0;
  --shadow-spread-none: 0;
  --shadow-x-100: 0;
  --shadow-x-200: 0;
  --shadow-x-300: 0;
  --shadow-x-400: 0;
  --shadow-x-500: 0;
  --shadow-x-600: 0;
  --shadow-x-none: 0;
  --shadow-y-none: 0;
  --shadow-y-100: 1px;
  --shadow-y-200: 3px;
  --shadow-y-300: 3px;
  --shadow-y-400: 5px;
  --shadow-y-500: 10px;
  --shadow-y-600: 16px;

  // flat colors
  --black-alpha-02: rgba(0, 0, 0, 0.02);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-06: rgba(0, 0, 0, 0.06);
  --black-alpha-08: rgba(0, 0, 0, 0.08);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-70: rgba(0, 0, 0, 0.7);
  --black-alpha-82: rgba(0, 0, 0, 0.82);
  --black-pure: rgb(0, 0, 0);
  --blue-100: rgb(219, 234, 254);
  --blue-200: rgb(191, 219, 254);
  --blue-300: rgb(147, 197, 253);
  --blue-400: rgb(96, 165, 250);
  --blue-50: rgb(239, 246, 255);
  --blue-500: rgb(59, 130, 246);
  --blue-500-alpha-10: rgba(59, 130, 246, 0.1);
  --blue-600: rgb(37, 99, 235);
  --blue-700: rgb(29, 78, 216);
  --blue-800: rgb(30, 64, 175);
  --blue-900: rgb(30, 58, 138);
  --brown-100: rgb(216, 197, 180);
  --brown-200: rgb(199, 172, 147);
  --brown-300: rgb(182, 146, 114);
  --brown-400: rgb(164, 121, 80);
  --brown-50: rgb(225, 210, 197);
  --brown-500: rgb(147, 95, 47);
  --brown-600: rgb(123, 80, 39);
  --brown-700: rgb(100, 65, 32);
  --brown-800: rgb(76, 49, 24);
  --brown-900: rgb(53, 34, 17);
  --green-100: rgb(220, 252, 231);
  --green-200: rgb(187, 247, 208);
  --green-300: rgb(134, 239, 172);
  --green-400: rgb(74, 222, 128);
  --green-50: rgb(240, 253, 244);
  --green-500: rgb(34, 197, 94);
  --green-600: rgb(22, 163, 74);
  --green-700: rgb(21, 128, 61);
  --green-800: rgb(22, 101, 52);
  --green-900: rgb(20, 83, 45);
  --neutral-100: rgb(245, 245, 245);
  --neutral-150: rgb(237, 237, 237);
  --neutral-200: rgb(229, 229, 229);
  --neutral-250: rgb(220, 220, 220);
  --neutral-300: rgb(212, 212, 212);
  --neutral-400: rgb(163, 163, 163);
  --neutral-50: rgb(250, 250, 250);
  --neutral-500: rgb(115, 115, 115);
  --neutral-600: rgb(82, 82, 82);
  --neutral-650: rgb(73, 73, 73);
  --neutral-700: rgb(64, 64, 64);
  --neutral-75: rgb(247, 247, 247);
  --neutral-750: rgb(51, 51, 51);
  --neutral-800: rgb(38, 38, 38);
  --neutral-850: rgb(30, 30, 30);
  --neutral-900: rgb(23, 23, 23);
  --neutral-925: rgb(19, 19, 19);
  --neutral-950: rgb(14, 14, 14);
  --orange-100: rgb(255, 237, 213);
  --orange-200: rgb(254, 215, 170);
  --orange-300: rgb(253, 186, 116);
  --orange-400: rgb(251, 146, 60);
  --orange-50: rgb(255, 247, 237);
  --orange-500: rgb(249, 115, 22);
  --orange-500-alpha-10: rgba(249, 115, 22, 0.1);
  --orange-600: rgb(234, 88, 12);
  --orange-700: rgb(194, 65, 12);
  --orange-800: rgb(154, 52, 18);
  --orange-900: rgb(124, 45, 18);
  --pink-100: rgb(241, 168, 198);
  --pink-200: rgb(235, 129, 173);
  --pink-300: rgb(228, 90, 148);
  --pink-400: rgb(222, 51, 122);
  --pink-50: rgb(244, 187, 211);
  --pink-500: rgb(216, 12, 97);
  --pink-600: rgb(181, 10, 81);
  --pink-700: rgb(147, 8, 66);
  --pink-800: rgb(112, 6, 50);
  --pink-900: rgb(78, 4, 35);
  --purple-100: rgb(243, 232, 255);
  --purple-200: rgb(233, 213, 255);
  --purple-300: rgb(216, 180, 254);
  --purple-400: rgb(192, 132, 252);
  --purple-50: rgb(250, 245, 255);
  --purple-500: rgb(168, 85, 247);
  --purple-600: rgb(147, 51, 234);
  --purple-700: rgb(126, 34, 206);
  --purple-800: rgb(107, 33, 168);
  --purple-900: rgb(88, 28, 135);
  --red-100: rgb(254, 226, 226);
  --red-200: rgb(254, 202, 202);
  --red-300: rgb(252, 165, 165);
  --red-400: rgb(248, 113, 113);
  --red-50: rgb(254, 242, 242);
  --red-500: rgb(239, 68, 68);
  --red-500-alpha-10: rgba(239, 68, 68, 0, 1);
  --red-600: rgb(252, 38, 38);
  --red-700: rgb(185, 28, 28);
  --red-800: rgb(153, 27, 27);
  --red-900: rgb(127, 29, 29);
  --teal-100: rgb(188, 231, 223);
  --teal-200: rgb(158, 221, 209);
  --teal-300: rgb(129, 210, 195);
  --teal-400: rgb(99, 200, 181);
  --teal-50: rgb(203, 237, 230);
  --teal-500: rgb(69, 189, 167);
  --teal-600: rgb(58, 159, 140);
  --teal-700: rgb(47, 129, 114);
  --teal-800: rgb(36, 98, 87);
  --teal-900: rgb(25, 68, 60);
  --white-alpha-0: rgba(255, 255, 255, 0);
  --white-alpha-02: rgba(255, 255, 255, 0.02);
  --white-alpha-05: rgba(255, 255, 255, 0.05);
  --white-alpha-06: rgba(255, 255, 255, 0.06);
  --white-alpha-08: rgba(255, 255, 255, 0.08);
  --white-alpha-10: rgba(255, 255, 255, 0.1);
  --white-alpha-15: rgba(255, 255, 255, 0.15);
  --white-alpha-70: rgba(255, 255, 255, 0.7);
  --white-alpha-82: rgba(255, 255, 255, 0.82);
  --white-pure: rgb(255, 255, 255);
}

// light theme colors
[data-theme="light"] {
  --color-asset-csv: var(--green-600);
  --color-asset-pdf: var(--red-600);
  --color-asset-zip: var(--orange-400);
  --color-asset-array: var(--blue-900);
  --color-asset-bioimg: var(--teal-600);
  --color-asset-dashboards: var(--blue-700);
  --color-asset-files: var(--blue-800);
  --color-asset-geometry: var(--orange-700);
  --color-asset-groups: var(--blue-700);
  --color-asset-ml-models: var(--pink-700);
  --color-asset-notebooks: var(--orange-500);
  --color-asset-point-cloud: var(--brown-700);
  --color-asset-raster: var(--brown-500);
  --color-asset-soma: var(--teal-800);
  --color-asset-task-graphs: var(--purple-800);
  --color-asset-udfs: var(--green-600);
  --color-asset-vcf: var(--teal-700);
  --color-asset-vector-search: var(--purple-600);
  --color-bg-active: var(--black-alpha-05);
  --color-bg-default: var(--neutral-100);
  --color-bg-disabled: var(--neutral-250);
  --color-bg-hovered: var(--black-alpha-02);
  --color-bg-app-active: var(--white-alpha-06);
  --color-bg-app-default: var(--neutral-850);
  --color-bg-app-hovered: var(--white-alpha-05);
  --color-bg-app-selected: var(--white-alpha-08);
  --color-bg-fill-active: var(--neutral-75);
  --color-bg-fill-default: var(--white-pure);
  --color-bg-fill-disabled: var(--black-alpha-05);
  --color-bg-fill-hover: var(--neutral-50);
  --color-bg-fill-selected: var(--neutral-100);
  --color-bg-fill-brand-active: var(--neutral-850);
  --color-bg-fill-brand-default: var(--neutral-950);
  --color-bg-fill-brand-disabled: var(--neutral-400);
  --color-bg-fill-brand-hover: var(--neutral-900);
  --color-bg-fill-critical-active: var(--red-800);
  --color-bg-fill-critical-default: var(--red-600);
  --color-bg-fill-critical-hover: var(--red-700);
  --color-bg-fill-emphasis-default: var(--blue-600);
  --color-bg-fill-info-default: var(--blue-500);
  --color-bg-fill-magic-default: var(--purple-600);
  --color-bg-fill-queued-default: var(--teal-700);
  --color-bg-fill-success-default: var(--green-500);
  --color-bg-fill-transparent-active: var(--black-alpha-08);
  --color-bg-fill-transparent-default: var(--black-alpha-05);
  --color-bg-fill-transparent-hover: var(--black-alpha-06);
  --color-bg-fill-transparent-selected: var(--black-alpha-10);
  --color-bg-fill-transparent-secondary-active: var(--black-alpha-10);
  --color-bg-fill-transparent-secondary-default: var(--black-alpha-06);
  --color-bg-fill-transparent-secondary-hover: var(--black-alpha-08);
  --color-bg-fill-unavailable-default: var(--orange-700);
  --color-bg-fill-warning-default: var(--orange-500);
  --color-bg-surface-active: var(--neutral-75);
  --color-bg-surface-default: var(--white-pure);
  --color-bg-surface-disabled: var(--black-alpha-05);
  --color-bg-surface-hover: var(--neutral-50);
  --color-bg-surface-selected: var(--neutral-100);
  --color-bg-surface-critical-active: var(--red-200);
  --color-bg-surface-critical-default: var(--red-50);
  --color-bg-surface-critical-hover: var(--red-100);
  --color-bg-surface-emphasis-active: var(--blue-700);
  --color-bg-surface-emphasis-default: var(--blue-500);
  --color-bg-surface-emphasis-hover: var(--blue-600);
  --color-bg-surface-info-active: var(--blue-300);
  --color-bg-surface-info-default: var(--blue-100);
  --color-bg-surface-info-hover: var(--blue-200);
  --color-bg-surface-magic-default: var(--purple-100);
  --color-bg-surface-secondary-active: var(--neutral-200);
  --color-bg-surface-secondary-default: var(--neutral-100);
  --color-bg-surface-secondary-disabled: var(--black-alpha-08);
  --color-bg-surface-secondary-hover: var(--neutral-150);
  --color-bg-surface-secondary-selected: var(--neutral-250);
  --color-bg-surface-secondary-critical-default: var(--red-50);
  --color-bg-surface-secondary-info-default: var(--blue-50);
  --color-bg-surface-secondary-warning-default: var(--orange-50);
  --color-bg-surface-success-active: var(--green-300);
  --color-bg-surface-success-default: var(--green-50);
  --color-bg-surface-success-hover: var(--green-200);
  --color-bg-surface-warning-active: var(--orange-300);
  --color-bg-surface-warning-default: var(--orange-100);
  --color-bg-surface-warning-hover: var(--orange-200);
  --color-border-default: var(--neutral-300);
  --color-border-disabled: var(--neutral-300);
  --color-border-focus: var(--black-pure);
  --color-border-hover: var(--neutral-250);
  --color-border-critical-active: var(--red-800);
  --color-border-critical-default: var(--red-600);
  --color-border-critical-hover: var(--red-700);
  --color-border-secondary-default: var(--neutral-150);
  --color-border-special-default: var(--white-alpha-0);
  --color-border-tetriary-default: var(--neutral-100);
  --color-icon-emphasis-default: var(--blue-600);
  --color-shadow-100: var(--black-alpha-08);
  --color-shadow-200: var(--black-alpha-08);
  --color-shadow-300: var(--black-alpha-15);
  --color-shadow-400: var(--black-alpha-15);
  --color-shadow-500: var(--black-alpha-15);
  --color-shadow-600: var(--black-alpha-15);
  --color-text-default: var(--neutral-800);
  --color-text-disabled: var(--neutral-400);
  --color-text-app-default: var(--white-alpha-70);
  --color-text-app-selected: var(--white-pure);
  --color-text-critical-active: var(--red-800);
  --color-text-critical-default: var(--red-600);
  --color-text-critical-hover: var(--red-700);
  --color-text-link-active: var(--blue-800);
  --color-text-link-default: var(--blue-600);
  --color-text-link-hover: var(--blue-700);
  --color-text-on-fill-default: var(--white-pure);
  --color-text-on-fill-brand-default: var(--neutral-50);
  --color-text-on-surface-critical-default: var(--red-900);
  --color-text-on-surface-info-default: var(--blue-900);
  --color-text-on-surface-magic-default: var(--purple-900);
  --color-text-on-surface-secondary-critical-default: var(--red-900);
  --color-text-on-surface-secondary-info-default: var(--blue-900);
  --color-text-on-surface-secondary-warning-default: var(--orange-900);
  --color-text-on-surface-success-default: var(--green-900);
  --color-text-on-surface-warning-default: var(--orange-900);
  --color-text-secondary-default: var(--neutral-600);
  --color-text-tertiary-default: var(--neutral-500);
}

// dark theme colors
[data-theme="dark"] {
  --color-asset-csv: var(--green-400);
  --color-asset-pdf: var(--red-500);
  --color-asset-zip: var(--orange-300);
  --color-asset-array: var(--blue-400);
  --color-asset-bioimg: var(--teal-500);
  --color-asset-dashboards: var(--blue-200);
  --color-asset-files: var(--blue-300);
  --color-asset-geometry: var(--orange-400);
  --color-asset-groups: var(--blue-500);
  --color-asset-ml-models: var(--pink-200);
  --color-asset-notebooks: var(--orange-300);
  --color-asset-point-cloud: var(--brown-400);
  --color-asset-raster: var(--brown-200);
  --color-asset-soma: var(--teal-600);
  --color-asset-task-graphs: var(--purple-400);
  --color-asset-udfs: var(--green-400);
  --color-asset-vcf: var(--teal-400);
  --color-asset-vector-search: var(--purple-300);
  --color-bg-active: var(--white-alpha-05);
  --color-bg-default: var(--neutral-900);
  --color-bg-disabled: var(--neutral-800);
  --color-bg-hovered: var(--white-alpha-02);
  --color-bg-app-active: var(--white-alpha-06);
  --color-bg-app-default: var(--black-pure);
  --color-bg-app-hovered: var(--white-alpha-05);
  --color-bg-app-selected: var(--white-alpha-08);
  --color-bg-fill-active: var(--neutral-950);
  --color-bg-fill-default: var(--black-pure);
  --color-bg-fill-disabled: var(--white-alpha-05);
  --color-bg-fill-hover: var(--neutral-900);
  --color-bg-fill-selected: var(--neutral-800);
  --color-bg-fill-brand-active: var(--neutral-150);
  --color-bg-fill-brand-default: var(--neutral-50);
  --color-bg-fill-brand-disabled: var(--neutral-600);
  --color-bg-fill-brand-hover: var(--neutral-100);
  --color-bg-fill-critical-active: var(--red-800);
  --color-bg-fill-critical-default: var(--red-600);
  --color-bg-fill-critical-hover: var(--red-700);
  --color-bg-fill-emphasis-default: var(--blue-500);
  --color-bg-fill-info-default: var(--blue-600);
  --color-bg-fill-magic-default: var(--purple-500);
  --color-bg-fill-queued-default: var(--teal-300);
  --color-bg-fill-success-default: var(--green-500);
  --color-bg-fill-transparent-active: var(--white-alpha-08);
  --color-bg-fill-transparent-default: var(--white-alpha-05);
  --color-bg-fill-transparent-hover: var(--white-alpha-06);
  --color-bg-fill-transparent-selected: var(--white-alpha-10);
  --color-bg-fill-transparent-secondary-active: var(--white-alpha-10);
  --color-bg-fill-transparent-secondary-default: var(--white-alpha-06);
  --color-bg-fill-transparent-secondary-hover: var(--white-alpha-08);
  --color-bg-fill-unavailable-default: var(--orange-500);
  --color-bg-fill-warning-default: var(--orange-400);
  --color-bg-surface-active: var(--neutral-750);
  --color-bg-surface-default: var(--neutral-850);
  --color-bg-surface-disabled: var(--white-alpha-05);
  --color-bg-surface-hover: var(--neutral-800);
  --color-bg-surface-selected: var(--neutral-750);
  --color-bg-surface-critical-active: var(--red-400);
  --color-bg-surface-critical-default: var(--red-200);
  --color-bg-surface-critical-hover: var(--red-300);
  --color-bg-surface-emphasis-active: var(--blue-500);
  --color-bg-surface-emphasis-default: var(--blue-300);
  --color-bg-surface-emphasis-hover: var(--blue-400);
  --color-bg-surface-info-active: var(--blue-400);
  --color-bg-surface-info-default: var(--blue-200);
  --color-bg-surface-info-hover: var(--blue-300);
  --color-bg-surface-magic-default: var(--purple-200);
  --color-bg-surface-secondary-active: var(--neutral-650);
  --color-bg-surface-secondary-default: var(--neutral-800);
  --color-bg-surface-secondary-disabled: var(--white-alpha-08);
  --color-bg-surface-secondary-hover: var(--neutral-700);
  --color-bg-surface-secondary-selected: var(--neutral-600);
  --color-bg-surface-secondary-critical-default: var(--red-500-alpha-10);
  --color-bg-surface-secondary-info-default: var(--blue-500-alpha-10);
  --color-bg-surface-secondary-warning-default: var(--orange-500-alpha-10);
  --color-bg-surface-success-active: var(--green-500);
  --color-bg-surface-success-default: var(--green-300);
  --color-bg-surface-success-hover: var(--green-400);
  --color-bg-surface-warning-active: var(--orange-500);
  --color-bg-surface-warning-default: var(--orange-300);
  --color-bg-surface-warning-hover: var(--orange-400);
  --color-border-default: var(--neutral-650);
  --color-border-disabled: var(--neutral-750);
  --color-border-focus: var(--white-pure);
  --color-border-hover: var(--neutral-600);
  --color-border-critical-active: var(--red-400);
  --color-border-critical-default: var(--red-600);
  --color-border-critical-hover: var(--red-500);
  --color-border-secondary-default: var(--neutral-750);
  --color-border-special-default: var(--neutral-650);
  --color-border-tetriary-default: var(--neutral-800);
  --color-icon-emphasis-default: var(--blue-400);
  --color-shadow-100: var(--black-alpha-70);
  --color-shadow-200: var(--black-alpha-60);
  --color-shadow-300: var(--black-alpha-70);
  --color-shadow-400: var(--black-alpha-82);
  --color-shadow-500: var(--black-alpha-82);
  --color-shadow-600: var(--black-alpha-82);
  --color-text-default: var(--neutral-100);
  --color-text-disabled: var(--neutral-300);
  --color-text-app-default: var(--white-alpha-70);
  --color-text-app-selected: var(--white-pure);
  --color-text-critical-active: var(--red-400);
  --color-text-critical-default: var(--red-600);
  --color-text-critical-hover: var(--red-500);
  --color-text-link-active: var(--blue-300);
  --color-text-link-default: var(--blue-500);
  --color-text-link-hover: var(--blue-400);
  --color-text-on-fill-default: var(--white-pure);
  --color-text-on-fill-brand-default: var(--neutral-950);
  --color-text-on-surface-critical-default: var(--red-900);
  --color-text-on-surface-info-default: var(--blue-900);
  --color-text-on-surface-magic-default: var(--purple-900);
  --color-text-on-surface-secondary-critical-default: var(--red-100);
  --color-text-on-surface-secondary-info-default: var(--blue-200);
  --color-text-on-surface-secondary-warning-default: var(--orange-200);
  --color-text-on-surface-success-default: var(--green-900);
  --color-text-on-surface-warning-default: var(--orange-900);
  --color-text-secondary-default: var(--neutral-300);
  --color-text-tertiary-default: var(--neutral-400);
}
