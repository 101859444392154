@import "../../assets/scss/breakpoints";

.burger {
  display: none;
  margin-right: 2rem;
  position: relative;

  @include mainSidebarCollapseBreakpoint {
    display: block;
  }
}
