.TDB-WorkspaceThemeSelector {
  &__menu {
    align-items: flex-start;
    background: var(--color-bg-surface-default);
    display: flex;
    flex-direction: column;
    gap: var(--space-200, 8px);
    width: 100%;
  }

  .TDB-Dialog {
    border: 1px solid var(--color-border-special-default);
    margin-left: -14px;
    width: 192px;
  }
}

.TDB-WorkspaceThemeItem {
  align-items: center;
  background-color: var(--color-bg-surface-default);
  border-radius: var(--border-radius-150);
  display: flex;
  justify-content: space-between;
  padding: var(--space-100) var(--space-400) var(--space-100) var(--space-150);
  transition: all 0.3s ease-in-out;
  width: 100%;

  &__content {
    align-items: flex-start;
    display: flex;
    gap: var(--space-200);
    padding: var(--space-200);
  }

  &:hover {
    background: var(--color-bg-surface-hover);
    cursor: pointer;
  }
}
