.TDB-Modal {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 4rem;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5000;

  &__Background {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 0.4s ease;
    width: 100vw;
    z-index: 5001;

    // Select both Modal or a form (since a modal might be wrapped in a form)
    & + form,
    & + .TDB-ModalDialog {
      position: absolute;
    }
  }

  &--visible {
    pointer-events: all;

    .TDB-Modal__Background {
      opacity: 0.9;
    }
  }

  form {
    // Fix margin when dialog is wrapped by a form (fixed vertical scrolling on overflow)
    margin: auto 0;
  }
}

[data-theme="dark"] {
  .TDB-Modal {
    &__Background {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
