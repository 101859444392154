@import "../../assets/scss/breakpoints";

.TDB-MainMenu {
  background-color: var(--color-bg-app-default);
  display: flex;
  flex-direction: column;
  gap: var(--space-300);
  height: 100%;
  justify-content: space-between;
  margin-right: var(--space-200);
  min-width: var(--space-1200);
  overflow-y: auto;
  padding-bottom: var(--space-200);

  &__section {
    display: flex;
    flex-direction: column;
    gap: var(--space-300);
  }

  &--helper {
    width: calc(var(--space-1200) + var(--space-200));
  }

  &::-webkit-scrollbar {
    background: var(--neutral-700);
    height: var(--space-200);
    width: var(--space-100);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral-500);
  }

  @include tabletBreakpoint {
    left: 0;
    padding: 1rem;
    position: fixed;
    top: 0;
    z-index: 3;

    &--mobile-hidden {
      display: none !important;
    }
  }

  &__on-click-outside {
    background-color: black;
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0.3;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;

    @include tabletBreakpoint {
      &--active {
        display: block;
      }
    }
  }
}
