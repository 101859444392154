.TDB-LazyLoadListWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  &__Loader {
    align-items: center;
    display: flex;
    height: 5rem; // fix overflow scroll glitch when content height is 0
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
  }
}
