$max: 10;
$offset: 1;
$unit: "rem";

@mixin spacings($className, $styleName) {
  $i: 0;
  $d: 0;

  #{$className + 'half'} {
    #{$styleName}: #{0.5 + $unit} !important;
  }

  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$d + $unit} !important;
    }
    $d: $d + $offset;
    $i: $i + 1;
  }
}

// margins
@include spacings(".ma-", "margin");
@include spacings(".mb-", "margin-bottom");
@include spacings(".mt-", "margin-top");
@include spacings(".ml-", "margin-left");
@include spacings(".mr-", "margin-right");
// paddings
@include spacings(".pa-", "padding");
@include spacings(".pb-", "padding-bottom");
@include spacings(".pt-", "padding-top");
@include spacings(".pl-", "padding-left");
@include spacings(".pr-", "padding-right");
