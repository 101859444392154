/**
 * This file includes all CSS Variables
 *
 * Use "Tokens" and not plain colors in order to support light & dark mode
 *
 * There is a data attribute in <body> which tells the app which theme to use
 * [data-theme='light'] or [data-theme='dark']
 */

:root {
  --color-scheme: light; // for scrollbars

  // Background
  --background-primary: hsla(200deg, 15%, 98%, 1);
  --background-secondary: hsla(210deg, 20%, 95%, 1);
  --background-deco: hsla(212deg, 32%, 77%, 1);
  --surface-primary: hsla(0deg, 0%, 100%, 1);
  --surface-primary-hovered: hsla(0deg, 0%, 100%, 1);
  --surface-primary-hovered-2: hsla(200deg, 60%, 99%, 1);
  --surface-secondary: hsla(200deg, 33%, 98%, 1);
  --surface-secondary-hovered: hsla(200deg, 33%, 98%, 1);
  --surface-secondary-hovered-2: hsla(200deg, 60%, 99%, 1);
  --tooltip: hsla(218deg, 25%, 27%, 1);
  --error-primary: hsla(0deg, 100%, 46%, 1);
  --error-primary-hovered: hsla(2deg, 100%, 41%, 1);
  --error-subdued: hsla(0deg, 100%, 46%, 0.1);
  --warning-subdued: hsla(44deg, 100%, 91%, 0.62);

  // Action
  --action-primary: hsla(212deg, 100%, 47%, 1);
  --action-primary-hovered: hsla(212deg, 100%, 37%, 1);
  --action-primary-disabled: hsla(212deg, 40%, 87%, 1);
  --action-surface: hsla(0deg, 0%, 0%, 0);
  --action-surface-hovered: hsla(200deg, 49%, 33%, 0.04);
  --action-surface-selected: hsla(200deg, 49%, 33%, 0.06);
  --action-surface-disabled: hsla(0deg, 0%, 0%, 0.04);
  --action-background: hsla(0deg, 0%, 100%, 0);
  --action-background-hovered: hsla(210deg, 30%, 93%, 1);
  --action-background-selected: hsla(212deg, 100%, 47%, 1);
  --action-background-disabled: hsla(0deg, 0%, 0%, 0.04);
  --action-subdued: hsla(212deg, 100%, 47%, 0.1);

  // Text
  --text-main-primary: hsla(0deg, 0%, 0%, 0.87);
  --text-main-secondary: hsla(0deg, 0%, 0%, 0.7);
  --text-main-tertiary: hsla(0deg, 0%, 0%, 0.49);
  --text-main-disabled: hsla(0deg, 0%, 0%, 0.38);
  --text-main-subtle: hsla(0deg, 0%, 0%, 0.08);
  --text-action-primary: hsla(0deg, 0%, 100%, 1);
  --text-action-alternative: hsla(0deg, 0%, 100%, 1);
  --text-action-brand: hsla(212deg, 100%, 47%, 1);
  --text-action-subdued: hsla(224deg, 100%, 15%, 0.76);
  --text-deco-primary: hsla(224deg, 47%, 38%, 1);
  --text-deco-secondary: hsla(222deg, 34%, 55%, 1);
  --text-error: hsla(0deg, 100%, 46%, 1);
  --text-error-hovered: hsla(2deg, 100%, 41%, 1);
  --text-warning: hsla(54deg, 42%, 68%, 1);
  --text-warning-vibrant: hsla(36deg, 89%, 41%, 1);
  --text-warning-vibrant-hovered: hsla(36deg, 89%, 36%, 1);
  --text-success-vibrant: hsla(159deg, 100%, 35%, 1);
  --text-success-vibrant-hovered: hsla(159deg, 100%, 30%, 1);

  // Border
  --border-input: hsla(212deg, 18%, 84%, 1);
  --border-input-hovered: hsla(212deg, 18%, 74%, 1);
  --border-input-selected: hsla(212deg, 100%, 47%, 1);
  --border-input-disabled: hsla(0deg, 0%, 90%, 1);
  --border-input-error: hsla(0deg, 100%, 46%, 1);
  --border-input-error-hovered: hsla(2deg, 100%, 41%, 1);
  --border-input-warning: hsla(54deg, 42%, 68%, 1);
  --border-tooltip: rgba(255, 255, 255, 0.1);

  // Box shadow
  --shadow-small: 0 1px 3px rgba(0, 0, 0, 0.16);
  --shadow-medium: 0 2px 10px rgba(0, 0, 0, 0.16);
  --shadow-large: 0 6px 18px rgba(0, 0, 0, 0.16);
  --shadow-xlarge: 0 16px 38px rgba(0, 0, 0, 0.16);
  --shadow-medium-brand: 0 6px 10px rgba(0, 112, 240, 0.11);
  --shadow-small-elevated: 0 1px 3px rgba(0, 0, 0, 0.16);
  --shadow-small-elevated-thick: 0 1px 3px rgba(0, 0, 0, 0.16);
  --shadow-medium-elevated: 0 2px 10px rgba(0, 0, 0, 0.16);
  --shadow-large-elevated: 0 6px 18px rgba(0, 0, 0, 0.16);
  --shadow-large-elevated-thick: 0 6px 18px rgba(0, 0, 0, 0.16);
  --shadow-xlarge-elevated: 0 16px 38px rgba(0, 0, 0, 0.16);

  // Misc colors
  --skeleton: var(--color-bg-default);
  --skeleton-lighten: hsla(0deg, 0%, 99%, 1);
  --solid-neutral: hsla(180deg, 2%, 76%, 1);

  // Global colors
  --white-transparent: hsla(0deg, 0%, 100%, 0.1);
  --black-transparent: hsla(0deg, 0%, 0%, 0.1);
  --white: hsla(0deg, 0%, 100%, 1);
  --favorite-primary: hsla(50deg, 100%, 47%, 1);

  // Asset colors
  --array-primary: linear-gradient(180deg, #0070f0 0%, #0065d8 100%);
  --array-hovered: linear-gradient(90deg, rgba(1, 112, 239, 0.2) 0%, rgba(1, 112, 239, 0) 88.57%);
  --array-secondary: hsla(212deg, 100%, 90%, 1);
  --notebook-primary: linear-gradient(180deg, #ffd53f 0%, #cd9400 100%);
  --notebook-hovered: linear-gradient(90deg, rgba(255, 218, 87, 0.3) 0%, rgba(255, 218, 87, 0) 88.57%);
  --notebook-secondary: hsla(36deg, 95%, 32%, 1);
  --mlmodel-primary: linear-gradient(180deg, #ff398c 0%, #ce0056 100%);
  --mlmodel-hovered: linear-gradient(90deg, rgba(255, 56, 139, 0.2) 0%, rgba(255, 56, 139, 0) 88.57%);
  --mlmodel-secondary: hsla(335deg, 100%, 90%, 1);
  --udf-primary: linear-gradient(180deg, #22d74a 0%, #00b628 100%);
  --udf-hovered: linear-gradient(90deg, rgba(34, 216, 74, 0.2) 0%, rgba(24, 205, 64, 0) 88.57%);
  --udf-secondary: hsla(133deg, 73%, 90%, 1);
  --dashboard-primary: linear-gradient(180deg, #0070f0 0%, #0065d8 100%);
  --dashboard-hovered: linear-gradient(90deg, rgba(1, 112, 239, 0.2) 0%, rgba(1, 112, 239, 0) 88.57%);
  --dashboard-secondary: hsla(212deg, 100%, 90%, 1);

  // V2 Shadows
  --shadow-0: none;
  /* stylelint-disable max-line-length */
  --shadow-100: var(--shadow-x-100) var(--shadow-y-100) var(--shadow-blur-100) var(--shadow-spread-100) var(--color-shadow-100);
  --shadow-200: var(--shadow-x-200) var(--shadow-y-200) var(--shadow-blur-200) var(--shadow-spread-200) var(--color-shadow-200);
  --shadow-300: var(--shadow-x-300) var(--shadow-y-300) var(--shadow-blur-300) var(--shadow-spread-300) var(--color-shadow-300);
  --shadow-400: var(--shadow-x-400) var(--shadow-y-400) var(--shadow-blur-400) var(--shadow-spread-400) var(--color-shadow-400);
  --shadow-500: var(--shadow-x-500) var(--shadow-y-500) var(--shadow-blur-500) var(--shadow-spread-500) var(--color-shadow-500);
  --shadow-600: var(--shadow-x-600) var(--shadow-y-600) var(--shadow-blur-600) var(--shadow-spread-600) var(--color-shadow-600);
}

/*
 * Dark mode tokens
 */
[data-theme="dark"] {
  --color-scheme: dark; // for scrollbars

  // Background
  --background-primary: hsla(206deg, 16%, 11%, 1);
  --background-secondary: hsla(206deg, 16%, 20%, 1);
  --background-deco: hsla(206deg, 15%, 40%, 1);
  --surface-primary: hsla(206deg, 16%, 18%, 1);
  --surface-primary-hovered: hsla(206deg, 16%, 22%, 1);
  --surface-primary-hovered-2: hsla(210deg, 20%, 26%, 1);
  --surface-secondary: hsla(206deg, 16%, 25%, 1);
  --surface-secondary-hovered: hsla(206deg, 16%, 29%, 1);
  --tooltip: hsla(218deg, 25%, 85%, 1);
  --error-primary: hsla(0deg, 100%, 62%, 1);
  --error-primary-hovered: hsla(0deg, 100%, 67%, 1);
  --error-subdued: hsla(0deg, 100%, 62%, 0.2);
  --warning-subdued: hsla(43deg, 100%, 77%, 0.2);

  // Action
  --action-primary: hsla(210deg, 100%, 52%, 1);
  --action-primary-hovered: hsla(210deg, 100%, 57%, 1);
  --action-primary-disabled: hsla(210deg, 40%, 17%, 1);
  --action-surface: hsla(0deg, 0%, 0%, 0);
  --action-surface-hovered: hsla(205deg, 13%, 38%, 0.3);
  --action-surface-selected: hsla(205deg, 13%, 38%, 0.35);
  --action-surface-disabled: hsla(0deg, 0%, 100%, 0.1);
  --action-background: hsla(0deg, 0%, 0%, 0);
  --action-background-hovered: hsla(210deg, 13%, 28%, 0.3);
  --action-background-selected: hsla(210deg, 100%, 52%, 1);
  --action-background-disabled: hsla(0deg, 0%, 100%, 0.1);
  --action-subdued: hsla(210deg, 100%, 52%, 0.2);

  // Text
  --text-main-primary: hsla(0deg, 0%, 100%, 1);
  --text-main-secondary: hsla(0deg, 0%, 100%, 0.86);
  --text-main-tertiary: hsla(0deg, 0%, 100%, 0.54);
  --text-main-disabled: hsla(0deg, 0%, 100%, 0.38);
  --text-main-subtle: hsla(0deg, 0%, 100%, 0.12);
  --text-action-primary: hsla(0deg, 0%, 100%, 1);
  --text-action-alternative: hsla(0deg, 0%, 0%, 0.87);
  --text-action-brand: hsla(210deg, 100%, 57%, 1);
  --text-action-subdued: hsla(0deg, 0%, 100%, 1);
  --text-deco-primary: hsla(216deg, 42%, 90%, 1);
  --text-deco-secondary: hsla(216deg, 42%, 78%, 1);
  --text-error: hsla(0deg, 100%, 62%, 1);
  --text-error-hovered: hsla(360deg, 100%, 67%, 1);
  --text-warning: hsla(43deg, 100%, 77%, 1);
  --text-warning-vibrant: hsla(36deg, 90%, 52%, 1);
  --text-warning-vibrant-hovered: hsla(36deg, 90%, 57%, 1);
  --text-success-vibrant: hsla(159deg, 100%, 50%, 1);
  --text-success-vibrant-hovered: hsla(159deg, 100%, 55%, 1);

  // Border
  --border-input: hsla(210deg, 10%, 36%, 1);
  --border-input-hovered: hsla(210deg, 10%, 46%, 1);
  --border-input-selected: hsla(210deg, 100%, 52%, 1);
  --border-input-disabled: hsla(205deg, 8%, 28%, 1);
  --border-input-error: hsla(360deg, 100%, 62%, 1);
  --border-input-error-hovered: hsla(360deg, 100%, 67%, 1);
  --border-input-warning: hsla(43deg, 100%, 77%, 1);
  --border-tooltip: rgba(0, 0, 0, 0.1);

  // Box shadow
  --shadow-small: none;
  --shadow-medium: none;
  --shadow-large: none;
  --shadow-xlarge: none;
  --shadow-medium-brand: 0 6px 10px rgba(10, 132, 255, 0.1);
  --shadow-small-elevated: 0 0 0 1px #40474f;
  --shadow-small-elevated-thick: 0 0 0 2px #40474f;
  --shadow-medium-elevated: 0 0 0 1px #40474f;
  --shadow-large-elevated: 0 0 0 1px #40474f;
  --shadow-large-elevated-thick: 0 0 0 1px #40474f;
  --shadow-xlarge-elevated: 0 0 0 1px #40474f;

  // Misc colors
  --skeleton: var(--color-bg-default);
  --skeleton-lighten: hsla(203deg, 13%, 24%, 1);
  --solid-neutral: hsla(0deg, 0%, 35%, 1);

  // Global colors
  --white-transparent: hsla(0deg, 0%, 100%, 0.1);
  --black-transparent: hsla(0deg, 0%, 0%, 0.1);
  --white: hsla(0deg, 0%, 100%, 1);
  --favorite-primary: hsla(50deg, 100%, 67%, 1);

  // Asset colors
  --array-primary: linear-gradient(180deg, #0070f0 0%, #0065d8 100%);

  ::selection {
    background-color: #365273;
  }
}
