.TDB-AuthPromptPlaceholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 6rem 2rem;
  width: 100%;

  &__title {
    margin-bottom: 2rem;
  }

  &__description {
    max-width: 360px;
  }

  &__image {
    margin: 5rem 0;
    max-width: 360px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

.TDB-AuthTemplate__Cube {
  height: 100%;
  width: 100%;
}
