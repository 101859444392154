// TODO: DEPRECATED
.font-size {
  &-d1 {
    font-size: 40px;
    line-height: 48px;
  }

  &-h5 {
    font-size: 32px;
    line-height: 48px;
  }

  &-h4 {
    font-size: 24px;
    line-height: 32px;
  }

  &-h3 {
    font-size: 20px;
    line-height: 24px;
  }

  &-h2 {
    font-size: 16px;
    line-height: 24px;
  }

  &-h1 {
    font-size: 14px;
    line-height: 16px;
  }

  &-p1 {
    font-size: 14px;
    line-height: 24px;
  }

  &-s1 {
    font-size: 12px;
    line-height: 16px;
  }

  &-s2 {
    font-size: 10px;
    line-height: 16px;
  }
}

.fw {
  &-200 {
    font-weight: 200;
  }

  &-400 {
    font-weight: 400;
  }

  &-600 {
    font-weight: 600;
  }

  &-800 {
    font-weight: 800;
  }
}
