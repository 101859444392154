.TDB-Avatar {
  align-items: center;
  background: var(--color-bg-surface-default);
  border-radius: var(--border-radius-150);
  box-shadow: var(--shadow-100);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;

  &--no-logo {
    padding: 0;
  }

  &--rounded {
    border-radius: 50%;
  }

  /** Sizes **/
  &--xs {
    height: var(--space-600);
    width: var(--space-600);
  }

  &--small {
    height: var(--space-800);
    width: var(--space-800);
  }

  &--medium {
    height: var(--space-1000);
    width: var(--space-1000);
  }

  &--large {
    height: var(--space-1200);
    width: var(--space-1200);
  }

  &--xl {
    height: 56px;
    width: 56px;
  }

  img {
    width: 100%;
  }
}
