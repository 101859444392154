.TDB-JupyterContainer {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4rem;
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 500px;
  z-index: -1;
}
