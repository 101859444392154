.alpha {
  &-100 {
    opacity: 1;
  }

  &-80 {
    opacity: 0.8;
  }

  &-60 {
    opacity: 0.6;
  }

  &-40 {
    opacity: 0.4;
  }

  &-20 {
    opacity: 0.2;
  }

  &-0 {
    opacity: 0;
  }
}
