@import "../../assets/scss/breakpoints";

.TDB-Page {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 3rem 5rem 5rem;
  position: relative;
  width: 100%;

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  @include mainSidebarCollapseBreakpoint {
    max-width: 100vw;
    padding: 3rem 2rem;
  }

  &--no-padding {
    padding: 0 !important;
  }
}
