.TDB-InboxButton {
  position: relative;

  &__badge {
    align-items: center;
    background-color: var(--text-action-brand);
    border-radius: 1.75rem;
    display: flex;
    height: 1.75rem;
    justify-content: center;
    min-width: 1.75rem;
    padding: 0.5rem;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    z-index: 2;
  }

  .TDB-InboxDialog {
    .TDB-Dialog {
      box-shadow: var(--shadow-400);
      margin-left: -2rem;
    }
  }
}
