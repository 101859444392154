.TDB-NotFound {
  h2 {
    color: var(--text-main-secondary);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    color: var(--text-main-primary);
    font-size: 14px;
    line-height: 1.71;
    max-width: 400px;
    opacity: 0.8;
    text-align: center;
  }

  .action {
    margin-top: 24px;
  }
}
