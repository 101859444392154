.itemWrapper {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.separator {
  padding: var(--space-200);
}

.popover {
  background: var(--color-bg-surface-default);
  border-radius: var(--border-radius-150);
  min-width: 220px;
  padding: var(--space-200);
}
