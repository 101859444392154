.show {
  &--tablet {
    display: none;
  }
}

/* Mobile styles */
@media screen and (max-width: 1023px) {
  .show--tablet {
    display: block;
  }

  .hide--tablet {
    display: none !important;
  }
}
