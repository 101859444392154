.button {
  align-items: center;
  background-color: transparent;
  border: none;
  border: 1px solid transparent;
  border-radius: var(--border-radius-150);
  display: flex;
  justify-content: center;
  position: relative;
  transition: all 0.1s ease;
  white-space: nowrap;

  svg path {
    transition: fill 0.1s ease;
  }
}

.content {
  align-items: center;
  display: inline-flex;
}

/** Removed 1px of padding from design to account for the 1px border **/
.small {
  height: 3rem;
  padding: 3px 8px;

  &.onlyIcon {
    padding: 4px;
  }

  .content {
    gap: var(--space-150);
  }
}

.medium {
  height: 4rem;
  padding: 7px 12px;

  &.onlyIcon {
    padding: 7px;
  }

  .content {
    gap: var(--space-200);
  }
}

.large {
  height: 5rem;
  padding: 11px 16px;

  &.onlyIcon {
    padding: 11px;
  }

  .content {
    gap: var(--space-300);
  }
}

.primary {
  background: var(--color-bg-fill-brand-default);
  color: var(--color-text-on-fill-brand-default);

  &:hover {
    background: var(--color-bg-fill-brand-hover);
  }

  &:active {
    background: var(--color-bg-fill-brand-active);
  }
}

.secondary {
  &:hover {
    background: var(--color-bg-fill-hover);
  }

  &:active {
    background: var(--color-bg-fill-active);
  }
}

.outlined {
  border: 1px solid var(--color-border-default);

  &:hover {
    background: var(--color-bg-fill-hover);
  }

  &:active {
    background: var(--color-bg-fill-active);
  }
}

.plain {
  .content {
    &:hover {
      text-decoration: underline;
    }
  }
}

.disabled {
  background: var(--color-bg-fill-brand-disabled);

  &:hover {
    background: var(--color-bg-fill-brand-disabled);
  }

  &.plain,
  &.outlined,
  &.secondary {
    background: var(--color-bg-fill-disabled);

    svg {
      fill: var(--color-text-disabled);
    }

    .text {
      color: var(--color-text-disabled);
    }
  }

  &.plain {
    background: transparent;
  }
}

.selected {
  &.outlined,
  &.secondary {
    background: var(--color-bg-fill-selected);

    svg {
      fill: var(--color-text-default);
    }
  }
}

.critical {
  &.primary {
    background-color: var(--color-bg-fill-critical-default);

    svg {
      fill: var(--color-text-on-fill-default);
    }

    .text {
      color: var(--color-text-on-fill-default);
    }

    &:hover {
      background-color: var(--color-bg-fill-critical-hover);
    }

    &:active {
      background-color: var(--color-bg-fill-critical-active);
    }
  }

  &.secondary,
  &.plain,
  &.outlined {
    background-color: transparent;

    svg {
      fill: var(--color-text-critical-default);
    }

    .text {
      color: var(--color-text-critical-default);
    }

    &:hover {
      svg {
        fill: var(--color-text-critical-hover);
      }

      .text {
        color: var(--color-text-critical-hover);
      }
    }

    .content {
      text-decoration: none;
    }

    &:active {
      svg {
        fill: var(--color-text-critical-active);
      }

      .text {
        color: var(--color-text-critical-active);
      }
    }
  }

  &.outlined {
    border-color: var(--color-border-critical-default);

    &:hover {
      border-color: var(--color-border-critical-hover);
    }

    &:active {
      border-color: var(--color-border-critical-active);
    }
  }
}

.fullWidth {
  width: 100%;
}

.loading {
  .content {
    display: none;
  }

  [aria-label="Loading"] {
    max-height: 12px;
  }

  &.primary {
    path {
      fill: var(--color-text-on-fill-brand-default) !important;
    }
  }

  &.outlined,
  &.plain,
  &.secondary {
    path {
      fill: var(--color-text-default) !important;
    }
  }
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}
