$main-sidebar-collapse-breakpoint: 1023px;
$mobile-breakpoint: 767px;
$tablet-breakpoint: 1023px;

@mixin mainSidebarCollapseBreakpoint {
  @media (max-width: #{$main-sidebar-collapse-breakpoint}) {
    @content;
  }
}

@mixin tabletBreakpoint {
  @media (max-width: #{$tablet-breakpoint}) {
    @content;
  }
}

@mixin mobileBreakpoint {
  @media (max-width: #{$mobile-breakpoint}) {
    @content;
  }
}
