.TDB-Tooltip {
  background-color: var(--tooltip);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-small);
  font-weight: 400;
  line-height: initial;
  max-width: 300px;
  z-index: 10;

  &__Wrapper {
    line-height: 0;

    &__inner {
      height: 100%;
      width: 100%;
    }
  }

  .TDB-Text {
    padding: 1rem 2rem;
  }

  &__List {
    display: flex;
    flex-direction: column;

    .TDB-Text {
      border-bottom: 1px solid var(--border-tooltip);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

#arrow,
#arrow::before {
  background-color: var(--tooltip);
  bottom: 0;
  height: 8px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  content: "";
  transform: rotate(45deg);
  visibility: visible;
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
  top: auto;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  bottom: auto;
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  left: auto;
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
  right: auto;
}

.react-joyride__beacon {
  span {
    &:first-child {
      background-color: var(--color-bg-fill-brand-disabled) !important;
    }

    &:last-child {
      border-color: var(--color-bg-fill-brand-disabled) !important;
    }
  }
}
