@import "../../../assets/scss/breakpoints";

.TDB-MainContent {
  background-color: var(--color-bg-surface-default);
  border-radius: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  @include tabletBreakpoint {
    border-radius: 0; // removes slack-like round border
  }

  &--overview {
    background-color: var(--color-bg-default);
    background-image: url("./assets/Backdrop_light_x1.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

[data-theme="dark"] {
  .TDB-MainContent--overview {
    background-image: url("./assets/Backdrop_dark_x1.png");
  }
}
