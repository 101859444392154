.TDB-Typography {
  color: var(--color-text-default);
  // Set body-md as default
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  // Font sizes
  &--heading-xxl {
    font-size: 40px;
    line-height: 48px;
  }

  &--heading-xl {
    font-size: 36px;
    line-height: 44px;
  }

  &--heading-lg {
    font-size: 30px;
    line-height: 36px;
  }

  &--heading-md {
    font-size: 24px;
    line-height: 30px;
  }

  &--heading-sm {
    font-size: 20px;
    line-height: 24px;
  }

  &--heading-xs {
    font-size: 16px;
    line-height: 20px;
  }

  &--body-xl {
    font-size: 20px;
    line-height: 24px;
  }

  &--body-lg {
    font-size: 16px;
    line-height: 20px;
  }

  &--body-lg-text {
    font-size: 16px;
    line-height: 26px;
  }

  &--body-md {
    font-size: 13px;
    line-height: 16px;
  }

  &--body-md-text {
    font-size: 13px;
    line-height: 20px;
  }

  &--body-sm {
    font-size: 12px;
    line-height: 16px;
  }

  &--body-sm-text {
    font-size: 12px;
    line-height: 19px;
  }

  &--body-xs {
    font-size: 11px;
    line-height: 14px;
  }

  &--body-xs-text {
    font-size: 11px;
    line-height: 18px;
  }

  &--body-xxs {
    font-size: 9px;
    line-height: 12px;
  }

  // Font weights
  &--fw-400 {
    font-weight: 400;
  }

  &--fw-500 {
    font-weight: 500;
  }

  &--fw-600 {
    font-weight: 600;
  }

  &--fw-700 {
    font-weight: 700;
  }

  &--fw-800 {
    font-weight: 800;
  }

  // Variants
  &--default {
    color: var(--color-text-default);
  }

  &--disabled {
    color: var(--color-text-disabled);
  }

  &--app-default {
    color: var(--color-text-app-default);
  }

  &--tertiary {
    color: var(--color-text-tertiary-default);
  }

  &--app-selected {
    color: var(--color-text-app-selected);
  }

  &--link {
    color: var(--color-text-link-default);

    &:hover {
      color: var(--color-text-link-hover);
    }

    &:active {
      color: var(--color-text-link-active);
    }
  }

  &--secondary {
    color: var(--color-text-secondary-default);
  }

  &--on-fill {
    color: var(--color-text-on-fill-default);
  }

  &--on-fill-brand {
    color: var(--color-text-on-fill-brand-default);
  }

  &--on-surface-info {
    color: var(--color-text-on-surface-info-default);
  }

  &--on-surface-secondary-info {
    color: var(--color-text-on-surface-secondary-info-default);
  }

  &--align-left {
    text-align: left;
  }

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }

  // Hover effects
  &--hover {
    &-underline {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &--ellipsis {
    max-width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  &--no-wrap {
    white-space: nowrap;
  }
}
