.TDB-InboxContent {
  background-color: var(--color-bg-surface-default);
  border-radius: var(--border-radius-200);
  max-height: calc(100vh - 15rem);
  max-width: calc(100vw - 6rem);
  padding: 2rem;
  width: 560px;

  &__header {
    align-items: center;
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    min-height: 5rem;

    hr {
      border-color: var(--border-input-disabled);
      flex: 1;
      opacity: 0.3;
    }

    &__bulk-actions {
      align-items: center;
      display: flex;
      gap: 1rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: calc(100vh - 30rem);
    overflow: hidden;
  }

  .TDB-NoActivitySVG {
    width: 260px;
  }

  &__no-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
