.active {
  &.icon {
    &--array {
      fill: var(--color-asset-array) !important;
    }

    &--file {
      fill: var(--color-asset-files) !important;
    }

    &--vcf {
      fill: var(--color-asset-vcf) !important;
    }

    &--soma {
      fill: var(--color-asset-soma) !important;
    }

    &--bioimg {
      fill: var(--color-asset-bioimg) !important;
    }

    &--vector_search {
      fill: var(--color-asset-vector-search) !important;
    }

    &--pointcloud {
      fill: var(--color-asset-point-cloud) !important;
    }

    &--geometry {
      fill: var(--color-asset-geometry) !important;
    }

    &--raster {
      fill: var(--color-asset-raster) !important;
    }

    &--notebook {
      fill: var(--color-asset-notebooks) !important;
    }

    &--dashboard {
      fill: var(--color-asset-dashboards) !important;
    }

    &--registered_task_graph {
      fill: var(--color-asset-task-graphs) !important;
    }

    &--user_defined_function {
      fill: var(--color-asset-udfs) !important;
    }

    &--ml_model {
      fill: var(--color-asset-ml-models) !important;
    }

    &--group {
      fill: var(--color-asset-groups) !important;
    }

    // By mime-type
    &--mime-type--application-vnd-tiledb-v1-array {
      fill: var(--color-asset-array) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-notebook {
      fill: var(--color-asset-notebooks) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-user_defined_function {
      fill: var(--color-asset-udfs) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-ml_model {
      fill: var(--color-asset-ml-models) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-registered_task_graph {
      fill: var(--color-asset-task-graphs) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-group {
      fill: var(--color-asset-groups) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-vcf {
      fill: var(--color-asset-vcf) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-soma {
      fill: var(--color-asset-soma) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-pointcloud {
      fill: var(--color-asset-point-cloud) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-bioimg {
      fill: var(--color-asset-bioimg) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-geometry {
      fill: var(--color-asset-geometry) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-raster {
      fill: var(--color-asset-raster) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-vector_search {
      fill: var(--color-asset-vector-search) !important;
    }

    &--mime-type--application-vnd-tiledb-v1-octet-stream {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--text-csv {
      fill: var(--color-asset-csv) !important;
    }

    &--mime-type--application-pdf {
      fill: var(--color-asset-pdf) !important;
    }

    &--mime-type--application-image-jpeg {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-image-png {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-image-tiff {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-text-plain {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-application-vnd-openxmlformats-officedocument-wordprocessingml-document {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-json {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-gzip {
      fill: var(--color-asset-zip) !important;
    }

    &--mime-type--audio-wav {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--audio-text-html {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--audio-text-vcard {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-x-hdf {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--application-x-tar {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--image-gif {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--text-xml {
      fill: var(--color-asset-files) !important;
    }

    &--mime-type--video-mp4 {
      fill: var(--color-asset-files) !important;
    }
  }
}
