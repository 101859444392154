.TDB-Text {
  // Set body-md-regular as default
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -1.3%;
  line-height: 20px;
  text-align: left;

  // Font sizes
  &--display-1 {
    font-size: 32px;
    letter-spacing: -0.4%;
    line-height: 38px;
  }

  &--heading-1 {
    font-size: 20px;
    letter-spacing: -1.3%;
    line-height: 24px;
  }

  &--heading-2 {
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
  }

  &--body-lg {
    font-size: 16px;
    letter-spacing: -1.3%;
    line-height: 24px;
  }

  &--body-md {
    font-size: 14px;
    letter-spacing: -1.3%;
    line-height: 20px;
  }

  &--subtext {
    font-size: 12px;
    letter-spacing: auto;
    line-height: 20px;
  }

  &--subtext-small {
    font-size: 8px;
    letter-spacing: auto;
    line-height: 10px;
  }

  // Font weights
  &--fw-regular {
    font-weight: 400;
  }

  &--fw-medium {
    font-weight: 500;
  }

  &--fw-semi-bold {
    font-weight: 600;
  }

  &--fw-bold {
    font-weight: 700;
  }

  &--fw-extra-bold {
    font-weight: 700;
  }

  // Hover effects
  &--hover {
    &-underline {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &--ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  &--no-wrap {
    white-space: nowrap;
  }
}
