.item {
  padding: 4px 0;
}

.itemWrapper {
  align-items: center;
  gap: 0.5rem;
}

.clickable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.active {
  color: var(--color-text-tertiary-default);
}

.block {
  border-radius: var(--border-radius-150);
  padding: var(--space-200);

  &:hover {
    background: var(--color-bg-surface-hover);
  }
}

.itemWrapper,
.container {
  display: flex;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
}

.hidden {
  left: -9999px;
  position: fixed;
}

.container {
  &:not(:last-child) {
    flex-shrink: 0;
  }

  &:last-child {
    min-width: 15px;
  }
}
