.TDB-Loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  path {
    fill: var(--text-action-brand);
  }
}

[data-theme="dark"] {
  .TDB-Loader {
    path {
      fill: hsl(212deg, 100%, 65%);
    }
  }
}
