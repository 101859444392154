.TDB-Dialog {
  background: var(--color-bg-surface-default);
  border-radius: var(--border-radius-500);
  border-radius: 1rem;
  box-shadow: var(--shadow-400);
  font-weight: 400;
  line-height: initial;
  padding: 1rem;
  z-index: 10;

  &--transparent {
    background: transparent;
    padding: 0;
  }

  &__Wrapper {
    line-height: 0;
  }
}
