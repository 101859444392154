/*
  CSS classes for flex property
*/

.row {
  flex-direction: row !important;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.column {
  flex-direction: column !important;
}

.column-reverse {
  flex-direction: column-reverse !important;
}

.align-center {
  align-items: center !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-stretch {
  align-items: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flow-column {
  flex-flow: column !important;
}
