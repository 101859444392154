.TDB-SnackbarContainer {
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  margin-bottom: 3rem;
  margin-left: 3rem;
  position: fixed;
  z-index: 5002;

  .TDB-Snackbar {
    margin-top: 2rem;

    &:last-child {
      margin-top: 0;
    }
  }
}
