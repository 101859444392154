.TDB-Cookies {
  background-color: var(--color-bg-surface-default);
  border-radius: 1rem;
  bottom: 5rem;
  box-shadow: var(--shadow-400);
  max-width: 302px;
  padding: 3rem 4rem;
  position: fixed;
  right: 5rem;
  width: auto;
  z-index: 2000;

  &__buttons {
    justify-content: center;

    .TDB-Button {
      line-height: 1;
      min-height: 4rem;
      padding: 0 2rem;
    }
  }

  &__text {
    line-height: 3rem;
    text-align: center;
  }
}
