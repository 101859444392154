.content {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.container {
  flex: 1;
  overflow: hidden;
}
