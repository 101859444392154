@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/inter/Inter-Thin-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-Thin-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/inter/Inter-ThinItalic-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/inter/Inter-ExtraLight-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraLight-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/inter/Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/inter/Inter-Light-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-Light-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/inter/Inter-LightItalic-BETA.woff2") format("woff2"),
    url("../fonts/inter/Inter-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/inter/Inter-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/inter/Inter-Italic.woff2") format("woff2"),
    url("../fonts/inter/Inter-Italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/inter/Inter-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/inter/Inter-MediumItalic.woff2") format("woff2"),
    url("../fonts/inter/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/inter/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/inter/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/inter/Inter-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/inter/Inter-BoldItalic.woff2") format("woff2"),
    url("../fonts/inter/Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraBold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/inter/Inter-Black.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/inter/Inter-BlackItalic.woff2") format("woff2"),
    url("../fonts/inter/Inter-BlackItalic.woff") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-display: swap;
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  src: url("../fonts/inter/Inter-upright.var.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  src: url("../fonts/inter/Inter-italic.var.woff2") format("woff2");
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-display: swap;
  font-family: "Inter var experimental";
  font-style: oblique 0deg 10deg;
  font-weight: 100 900;
  src: url("../fonts/inter/Inter.var.woff2") format("woff2");
}
