.wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: var(--space-400);
  width: 100%;
}

.content {
  align-items: center;
  flex-direction: column;
  height: auto;
  justify-content: center;
  max-width: 100%;
  min-height: 360px;
  position: relative;
  width: 360px;
}

.buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--space-200);
  justify-content: center;
  margin-top: var(--space-600);
}

.graphic-wrapper {
  align-items: center;
  display: flex;
  height: 360px;
  justify-content: center;
  position: relative;
  width: 360px;
}

.main {
  position: relative;
}

.footer {
  margin-top: var(--space-800);
}

.background-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.graphic-img {
  background-repeat: no-repeat;
  background-size: contain;
  height: 220px;
  position: relative;
  width: 220px;
  z-index: 2;
}

.graphic-background {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.no-description {
  &--background {
    background-image: url("./assets/no-description/no-description-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-description/no-description-light.png");
  }
}

.no-recently-created {
  &--background {
    background-image: url("./assets/no-recently-created/no-recently-created-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-recently-created/no-recently-created-light.png");
  }
}

.no-active-apps {
  &--background {
    background-image: url("./assets/no-active-apps/no-active-apps-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-active-apps/no-active-apps-light.png");
  }
}

.no-notifications {
  &--background {
    background-image: url("./assets/no-notifications/no-notifications-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-notifications/no-notifications-light.png");
  }
}

.no-task-graphs {
  &--background {
    background-image: url("./assets/no-task-graphs/no-task-graphs-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-task-graphs/no-task-graphs-light.png");
  }
}

.no-tasks {
  &--background {
    background-image: url("./assets/no-tasks/no-tasks-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-tasks/no-tasks-light.png");
  }
}

.no-metadata {
  &--background {
    background-image: url("./assets/no-metadata/no-metadata-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-metadata/no-metadata-light.png");
  }
}

.no-marketplace-results {
  &--background {
    background-image: url("./assets/no-marketplace-results/no-marketplace-results-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-marketplace-results/no-marketplace-results-light.png");
  }
}

.no-payment-method {
  &--background {
    background-image: url("./assets/no-payment-method/no-payment-method-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-payment-method/no-payment-method-light.png");
  }
}

.no-invoices {
  &--background {
    background-image: url("./assets/no-invoices/no-invoices-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-invoices/no-invoices-light.png");
  }
}

.no-asset-preview {
  &--background {
    background-image: url("./assets/no-preview/no-preview-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-preview/no-preview-light.png");
  }
}

.no-api-tokens {
  &--background {
    background-image: url("./assets/no-api-tokens/no-api-tokens-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-api-tokens/no-api-tokens-light.png");
  }
}

.no-cloud-credentials {
  &--background {
    background-image: url("./assets/no-cloud-credentials/no-cloud-credentials-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-cloud-credentials/no-cloud-credentials-light.png");
  }
}

.unexpected-error {
  &--background {
    background-image: url("./assets/unexpected-error/unexpected-error-background-light.png");
  }

  &--img {
    background-image: url("./assets/unexpected-error/unexpected-error-light.png");
  }
}

.unauthorized-access {
  &--background {
    background-image: url("./assets/unauthorized-access/unauthorized-access-background-light.png");
  }

  &--img {
    background-image: url("./assets/unauthorized-access/unauthorized-access-light.png");
  }
}

.asset-sharing {
  &--background {
    background-image: url("./assets/asset-sharing/asset-sharing-background-light.png");
  }

  &--img {
    background-image: url("./assets/asset-sharing/asset-sharing-light.png");
  }
}

.no-asset-activity {
  &--background {
    background-image: url("./assets/no-asset-activity/no-asset-activity-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-asset-activity/no-asset-activity-light.png");
  }
}

.no-servers {
  &--background {
    background-image: url("./assets/no-servers/no-servers-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-servers/no-servers-light.png");
  }
}

.no-contents-groups {
  &--background {
    background-image: url("./assets/no-contents-groups/no-contents-groups-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-contents-groups/no-contents-groups-light.png");
  }
}

.no-assets {
  &--background {
    background-image: url("./assets/no-assets/no-assets-background-light.png");
  }

  &--img {
    background-image: url("./assets/no-assets/no-assets-light.png");
  }
}

.add-member {
  &--background {
    background-image: url("./assets/add-member/add-member-background-light.png");
  }

  &--img {
    background-image: url("./assets/add-member/add-member-light.png");
  }
}

.email-verification {
  &--background {
    background-image: url("./assets/email-verification/email-verification-background-light.png");
  }

  &--img {
    background-image: url("./assets/email-verification/email-verification-light.png");
  }
}

[data-theme="dark"] {
  .default {
    &--background {
      background-image: url("./assets/unexpected-error/unexpected-error-background-dark.png");
    }

    &--graphic {
      background-image: url("./assets/unexpected-error/unexpected-error-background-dark.png");
    }
  }

  .no-description {
    &--background {
      background-image: url("./assets/no-description/no-description-background-dark.png");
    }

    &--graphic {
      background-image: url("./assets/no-description/no-description-background-dark.png");
    }
  }

  .no-recently-created {
    &--background {
      background-image: url("./assets/no-recently-created/no-recently-created-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-recently-created/no-recently-created-dark.png");
    }
  }

  .no-notifications {
    &--background {
      background-image: url("./assets/no-notifications/no-notifications-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-notifications/no-notifications-dark.png");
    }
  }

  .no-task-graphs {
    &--background {
      background-image: url("./assets/no-task-graphs/no-task-graphs-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-task-graphs/no-task-graphs-dark.png");
    }
  }

  .no-tasks {
    &--background {
      background-image: url("./assets/no-tasks/no-tasks-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-tasks/no-tasks-dark.png");
    }
  }

  .no-metadata {
    &--background {
      background-image: url("./assets/no-metadata/no-metadata-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-metadata/no-metadata-dark.png");
    }
  }

  .no-marketplace-results {
    &--background {
      background-image: url("./assets/no-marketplace-results/no-marketplace-results-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-marketplace-results/no-marketplace-results-dark.png");
    }
  }

  .no-payment-method {
    &--background {
      background-image: url("./assets/no-payment-method/no-payment-method-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-payment-method/no-payment-method-dark.png");
    }
  }

  .no-invoices {
    &--background {
      background-image: url("./assets/no-invoices/no-invoices-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-invoices/no-invoices-dark.png");
    }
  }

  .no-asset-preview {
    &--background {
      background-image: url("./assets/no-preview/no-preview-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-preview/no-preview-dark.png");
    }
  }

  .no-api-tokens {
    &--background {
      background-image: url("./assets/no-api-tokens/no-api-tokens-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-api-tokens/no-api-tokens-dark.png");
    }
  }

  .no-active-apps {
    &--background {
      background-image: url("./assets/no-active-apps/no-active-apps-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-active-apps/no-active-apps-dark.png");
    }
  }

  .no-cloud-credentials {
    &--background {
      background-image: url("./assets/no-cloud-credentials/no-cloud-credentials-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-cloud-credentials/no-cloud-credentials-dark.png");
    }
  }

  .unexpected-error {
    &--background {
      background-image: url("./assets/unexpected-error/unexpected-error-background-dark.png");
    }

    &--img {
      background-image: url("./assets/unexpected-error/unexpected-error-dark.png");
    }
  }

  .unauthorized-access {
    &--background {
      background-image: url("./assets/unauthorized-access/unauthorized-access-background-dark.png");
    }

    &--img {
      background-image: url("./assets/unauthorized-access/unauthorized-access-dark.png");
    }
  }

  .asset-sharing {
    &--background {
      background-image: url("./assets/asset-sharing/asset-sharing-background-dark.png");
    }

    &--img {
      background-image: url("./assets/asset-sharing/asset-sharing-dark.png");
    }
  }

  .no-asset-activity {
    &--background {
      background-image: url("./assets/no-asset-activity/no-asset-activity-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-asset-activity/no-asset-activity-dark.png");
    }
  }

  .no-servers {
    &--background {
      background-image: url("./assets/no-servers/no-servers-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-servers/no-servers-dark.png");
    }
  }

  .no-contents-groups {
    &--background {
      background-image: url("./assets/no-contents-groups/no-contents-groups-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-contents-groups/no-contents-groups-dark.png");
    }
  }

  .no-assets {
    &--background {
      background-image: url("./assets/no-assets/no-assets-background-dark.png");
    }

    &--img {
      background-image: url("./assets/no-assets/no-assets-dark.png");
    }
  }

  .add-member {
    &--background {
      background-image: url("./assets/add-member/add-member-background-dark.png");
    }

    &--img {
      background-image: url("./assets/add-member/add-member-dark.png");
    }
  }

  .email-verification {
    &--background {
      background-image: url("./assets/email-verification/email-verification-background-dark.png");
    }

    &--img {
      background-image: url("./assets/email-verification/email-verification-dark.png");
    }
  }
}
