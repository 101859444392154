.TDB-WorkspaceMenu {
  align-items: flex-start;
  background-color: var(--color-bg-surface-default);
  background-image: url("./assets/bg_light.svg");
  background-repeat: no-repeat;
  border: 1px solid var(--color-border-special-default);
  border-radius: var(--border-radius-300);
  box-shadow: var(--shadow-400);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: var(--space-300);
  left: 1rem;
  max-height: calc(100vh - 11rem);
  max-width: 296px;
  overflow-y: auto;
  padding: var(--space-400) var(--space-200) var(--space-200) var(--space-200);
  position: fixed;
  top: 8rem;
  z-index: 1002;

  &--active {
    display: block;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__search {
    width: 100%;
  }

  &__avatar {
    margin-bottom: 1rem;
  }

  &__list {
    border-bottom: 1px solid var(--color-border-tetriary-default);
    border-top: 1px solid var(--color-border-tetriary-default);
    gap: var(--space-100);
    padding: var(--space-300) 0;
    position: relative;

    &--overflow {
      &::after {
        align-items: center;
        /* stylelint-disable-next-line max-line-length */
        background: linear-gradient(0deg, color-mix(in srgb, var(--color-bg-surface-default) 70%, transparent) 30%, rgba(0, 0, 0, 0) 100%);
        bottom: 0;
        content: "";
        display: flex;
        height: 80px;
        justify-content: center;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__inner-list {
    max-height: 384px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: var(--background-primary);
      height: 8px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--background-secondary);
    }
  }

  &__main {
    width: 100%;
  }

  &__footer {
    border-top: 1px solid var(--color-border-tetriary-default);
    width: 100%;
  }

  &__placeholder {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 280px;
    padding: 0 1rem;
  }

  &__icon {
    max-width: 100%;
  }
}

.TDB-WorkspaceMenuLayer {
  background-color: hsla(0deg, 0%, 0%, 0.14);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

[data-theme="dark"] {
  .TDB-WorkspaceMenu {
    background-image: url("./assets/bg_dark.svg");
  }
}
