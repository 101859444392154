.TDB-NamespaceSelector {
  display: flex;
  justify-content: center;
  position: relative;

  &__avatar {
    background-color: var(--color-bg-surface-default);
    border-radius: var(--border-radius-200);
    height: var(--space-1200) !important;
    position: relative;
    width: var(--space-1200) !important;
    z-index: 3;
  }

  &__sub-card {
    background-color: #6e6e6e;
    border-radius: var(--border-radius-200);
    height: var(--space-1200);
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 3px;
    width: var(--space-1000);
    z-index: 2;

    &--2 {
      background-color: #454545;
      top: var(--space-150);
      width: var(--space-800);
      z-index: 1;
    }
  }
}
