@import "./breakpoints";

:root {
  --sidebar-width: 30rem;
  --header-height: var(--height-1200);
  --header-height-mobile: var(--height-1200);
  --user-sidebar-width: 36rem;
}

@include tabletBreakpoint {
  :root {
    --header-height: 8rem;
  }
}
